Mosaic.setPlugins([require('./../packages/@bodymod/post-nord/src/plugin/CheckoutDeliveryOption.plugin.js'),
require('./../packages/@bodymod/post-nord/src/plugin/CheckoutShipping.plugin.js'),
require('./../packages/@bodymod/post-nord/src/plugin/StoreReducer.plugin.js'),
require('./../packages/@bodymod/packeta/src/plugin/CheckoutDeliveryOption.plugin.js'),
require('./../packages/@bodymod/packeta/src/plugin/CheckoutShipping.plugin.js'),
require('./../packages/@bodymod/packeta/src/plugin/StoreReducer.plugin.js'),
require('./../packages/@bodymod/sale-countdown/src/plugin/ConfigDispatcher.plugin.js'),
require('./../packages/@bodymod/sale-countdown/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@bodymod/sale-countdown/src/plugin/ConfigReducer.plugin.js'),
require('./../packages/@bodymod/sale-countdown/src/plugin/ProductActions.plugin.js'),
require('./../packages/@bodymod/seo/src/plugin/SeoDataQuery.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/CartItemChangeQuantity.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/CartPageContainer.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/CheckoutBillingContainer.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/CheckoutPurchasePrepare.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/CheckoutShippingContainer.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/CheckoutSuccessComponent.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/HandleCustomerDataOnInit.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/HomePage.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/MyAccountQuery.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/NoMatch.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/ProductClick.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/ProductList.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/ProductListPageComponent.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/ProductListQuery.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/ProductPageContainer.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/Router.container.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/RouterComponentPlugin.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/SearchFieldComponent.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/SearchItemContainer.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/SearchOverlayComponent.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/StoreReducer.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/WishlistItemComponent.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/addToCart.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/myAccountLoginRegister.plugin.js'),
require('./../packages/@scandipwa/gtm-new/src/plugin/wishlistItemAddToCart.plugin.js'),
require('./../packages/@scandipwa/gdpr-cookie/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@scandipwa/gdpr-cookie/src/plugin/GdprCookiePage.plugin.js'),
require('./../packages/@scandipwa/gdpr-cookie/src/plugin/Router.plugin.js'),
require('./../packages/@scandipwa/gdpr-cookie/src/plugin/StoreIndex.plugin.js'),
require('./../packages/@scandipwa/gdpr/src/plugin/Checkout.plugin.js'),
require('./../packages/@scandipwa/gdpr/src/plugin/CheckoutBilling.plugin.js'),
require('./../packages/@scandipwa/gdpr/src/plugin/Html.plugin.js'),
require('./../packages/@scandipwa/gdpr/src/plugin/MyAccount.plugin.js'),
require('./../packages/@scandipwa/gdpr/src/plugin/MyAccountCreateAccount.plugin.js'),
require('./../packages/@scandipwa/gdpr/src/plugin/Notification.plugin.js'),
require('./../packages/@scandipwa/gdpr/src/plugin/Router.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/CategoryDetails.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/CategoryFilterOverlayContainer.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/CategoryPageContainer.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/CategoryReducer.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/CmsBlockContainer.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/NavigationAbstractContainer.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/ProductListInfoDispatcher.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/ProductListQuery.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/ReduceFilters.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/ResetAllButton.plugin.js'),
require('./../packages/@scandipwa/shopbyseo/src/plugin/UrlRewritesQuery.plugin.js'),
require('./../packages/@scandipwa/adyen-payment/src/plugin/CheckoutBillingContainer.plugin.js'),
require('./../packages/@scandipwa/adyen-payment/src/plugin/CheckoutComponent.plugin.js'),
require('./../packages/@scandipwa/adyen-payment/src/plugin/CheckoutContainer.plugin.js'),
require('./../packages/@scandipwa/adyen-payment/src/plugin/CheckoutPaymentsComponent.plugin.js'),
require('./../packages/@scandipwa/adyen-payment/src/plugin/CheckoutPaymentsContainer.plugin.js'),
require('./../packages/@scandipwa/adyen-payment/src/plugin/CheckoutQuery.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/Cart.query.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/CartOverlay.component.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/CartPage.component.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/Checkout.component.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/Checkout.container.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/Checkout.query.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/CheckoutBilling.component.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/CheckoutBilling.container.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/CheckoutPayments.component.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/CheckoutPayments.container.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/Router.container.plugin.js'),
require('./../packages/@scandipwa/paypal/src/plugin/StoreStaticReducers.plugin.js'),
require('./../packages/@scandipwa/layout-updates/src/plugin/block/AllRoutes.plugin.js'),
require('./../packages/@scandipwa/layout-updates/src/plugin/block/CartOverlay.plugin.js'),
require('./../packages/@scandipwa/layout-updates/src/plugin/block/CheckoutSuccess.plugin.js'),
require('./../packages/@scandipwa/layout-updates/src/plugin/block/Footer.plugin.js'),
require('./../packages/@scandipwa/layout-updates/src/plugin/block/Header.plugin.js'),
require('./../packages/@scandipwa/layout-updates/src/plugin/block/ProductActions.plugin.js'),
require('./../packages/@scandipwa/layout-updates/src/plugin/block/ProductGallery.plugin.js'),
require('./../packages/@scandipwa/layout-updates/src/plugin/block/ProductReviews.plugin.js'),
require('./../packages/@scandipwa/layout-updates/src/plugin/block/Router.plugin.js'),
require('./../packages/@scandipwa/layout-updates/src/plugin/config/ConfigDispatcher.plugin.js'),
require('./../packages/@scandipwa/layout-updates/src/plugin/config/ConfigReducer.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/Header.container.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/Header.dispatch.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/Header.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/Header.state.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/Menu.container.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/Menu.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/Menu.state.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/MenuItem.container.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/MenuItem.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/MenuItem.state.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/ProductActions.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/Router.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/SearchField.dispatch.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/SearchField.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/SearchItem.container.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/SearchItem.dispatch.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/SearchOverlay.container.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/SearchOverlay.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/SearchOverlay.state.plugin.js'),
require('./../packages/@scandipwa/upper-navigation-extension/src/plugin/Store.plugin.js'),
require('./../packages/@scandipwa/page-builder/src/plugin/CmsPage.container.plugin.js')]);
// eslint-disable-next-line simple-import-sort/sort
import 'react-app-polyfill/ie11';
import 'Util/Polyfill';
import 'Style/main';
import * as Sentry from '@sentry/browser';
import { render } from 'react-dom';
import { CURRENT_VERSION } from 'Component/NewVersionPopup/NewVersionPopup.config';

import App from 'Component/App';

Sentry.init({
    dsn: 'https://b89e256ab6a34f8aa0c479260effc07e@app.glitchtip.com/6147',
    release: `BodPwa@${CURRENT_VERSION}`,
    autoSessionTracking: false,
    allowUrls: [/https?:\/\/((cdn|www|preprod)\.)?bodymod\.[a-z]+/]
});

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker
            .register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));
