import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import CategoryReducer from 'Store/Category/Category.reducer';
import ProductReducer from 'Store/Product/Product.reducer';
import { setPageType } from 'Store/UrlRewrites/UrlRewrites.action';
import componentLoader from 'Util/componentLoader';
import { withReducers } from 'Util/DynamicReducer';
/** @namespace Bodypwa/Route/UrlRewrites/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

export const UrlRewritesDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/UrlRewrites/UrlRewrites.dispatcher'
), 2);

export const NoMatchDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NoMatch/NoMatch.dispatcher'
), 2);

/** @namespace Bodypwa/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestUrlRewrite: (urlParam) => {
        UrlRewritesDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, { urlParam })
        );
    },
    setPageType: (type) => dispatch(setPageType(type))
});

/** @namespace Bodypwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    componentDidUpdate() {
        const { isLoading } = this.props;

        /**
         * If the latest requested URL rewrite is not related
         * to the current location, and the URL rewrites are not loading
         * request new URL rewrite.
         */
        if (this.getIsLoading() && !isLoading) {
            this.requestUrlRewrite();
        }

        /**
         * Make sure that PDP & PLP url don't have "/" in the end
         */
        this.redirectToCorrectUrl();
    }

    redirectToCorrectUrl() {
        const { setPageType } = this.props;

        const type = this.getType();

        setPageType(type);
    }

    requestUrlRewrite() {
        const { requestUrlRewrite } = this.props;

        return requestUrlRewrite(location.pathname);
    }
}

export default withReducers({
    CategoryReducer,
    ProductReducer
})(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UrlRewritesContainer)
);
